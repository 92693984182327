import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {AppUpdateService} from "../../services/app-update.service";

@Component({
  selector: 'app-check-appversion',
  templateUrl: './check-appversion.component.html',
  styleUrls: ['./check-appversion.component.scss'],
  standalone: true
})
export class CheckAppversionComponent implements OnInit, OnDestroy {
  interval : any = null;
  constructor(private appUpdateService : AppUpdateService) { }

  ngOnInit() {
    this.appUpdateService.init();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

}
