import {Injectable, isDevMode} from '@angular/core';
import {NavController, ToastController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    currentToast: any = null;

    constructor(
        private readonly _toastController: ToastController,
        private readonly _translateService: TranslateService
    ) {}

    async success(message: string) {
        this.main(message,'success' , null);
    }

    async error(message: string, debugInfo = null) {
        this.main(message,'error' , debugInfo);
    }

    async warning(message: string, debugInfo = null) {
        this.main(message,'warning' , debugInfo);
    }


    async main(message: string, cssClass: string, debugInfo = null) {
        if(this.currentToast != null){
            this.currentToast.dismiss();
            setTimeout(() => {
                this.currentToast = null;
                this.main(message,cssClass,debugInfo);
            },200);
            return;
        }

        let m = this._translateService.instant(message);

        if (isDevMode() && debugInfo != null) {
            m += '<br /><br />Debug : ' + debugInfo;
        }

        this.currentToast = await this._toastController.create({
            message: m,
            duration: 2000,
            position: 'top',
            cssClass : cssClass
        });

        this.currentToast.present();
    }
}
