import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {StorageService} from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor{
  constructor(private storage: StorageService) {
  }
  // Add a Bearer token on every request sent (except for the exclude ones)
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storage.get('access_token');
    if(!req.headers.has('Authorization')){
      const modifiedRequest = req.clone(
        {headers: req.headers.append('Authorization', 'Bearer '+token )}
      );
      return this.isExcluded(req.url) ? next.handle(req) : next.handle(modifiedRequest);
    }else{
      if (this.isExcluded(req.url) && req.headers.has('Authorization')) {
        req = req.clone(
          { headers: req.headers.delete('authorization')}
        );
      }
      return next.handle(req);
    }
  }

  isExcluded(url: string): boolean{
    const excludeUrls = [
      `${environment.apiUrl}/api/v1/login`,
      `${environment.apiUrl}/api/v1/request-password/forgot-password`,
      `${environment.apiUrl}/api/v1/reset-password`,
      `${environment.apiUrl}/api/v1/public`,
    ];
    for (const str of excludeUrls) {
      if (url.startsWith(str)) {
        return true;
      }
    }
    return false;
  }

}
