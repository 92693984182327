import { Injectable } from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {ToastService} from "./toast.service";

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  constructor(private readonly updates: SwUpdate, private toastService : ToastService) {
    this.updates.versionUpdates.subscribe(async evt => {
      switch (evt.type) {
        case 'VERSION_READY':
          this.showAppUpdateAlert();
          break;
      }
    });
  }

  init() {
    if (this.updates.isEnabled) {
      this.updates.checkForUpdate().then(() => {});
    } else {
    }
  }

  showAppUpdateAlert() {
    this.toastService.warning("Une mise à jour a été détectée, téléchargement en cours ...");

    setTimeout(() => {
      this.doAppUpdate();
    },2000);
  }
  doAppUpdate() {
    this.updates.activateUpdate().then(() => {
      this.toastService.success("Mise à jour terminée, merci.");
      setTimeout(() => {
        document.location.reload();
      },2000);
    });
  }
}
