<ion-content [fullscreen]="true" scroll-y="false" >
  <div class="row">
    <div class="left-illustration">
      <div class="logo"></div>
      <div class="background-logo"></div>
      <div class="illu"></div>
    </div>
    <div class="col">
      <ion-content [fullscreen]="true" scroll-y="false">
        <ion-router-outlet [animated]="false" ></ion-router-outlet>
      </ion-content>
    </div>
  </div>
</ion-content>
