import { Routes } from '@angular/router';
import {AuthPage} from "./auth/auth.page";
import {AdminPage} from "./home/admin/admin.page";
import {HomePage} from "./home/home.page";
import {AuthGuardService} from "./services/guards/auth-guard.service";

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.routes').then((m) => m.homeRoutes),
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'auth',
    component: AuthPage,
    loadChildren: () => import('./auth/auth.routes').then((m) => m.authRoutes),
  },
];
