import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";
import {CheckAppversionComponent} from "./components/check-appversion/check-appversion.component";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CheckAppversionComponent],
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('fr');
    this.translate.addLangs(['fr']);
  }
}
