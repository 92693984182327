import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {}

  public set(key: string, value: any) {
    const valueConverted: string = (typeof value === 'string' ? value : JSON.stringify(value) );
    localStorage.setItem(key, valueConverted);
  }

  public get(key: string){
    return this.convertedValue(localStorage.getItem(key));
  }

  public remove(key: string) {
    localStorage.removeItem(key);
  }

  public clearAll() {
    localStorage.clear();
  }

  convertedValue(value: any) {
    if(typeof value === 'string'){
      if(this.isJsonString(value)){
        return JSON.parse(value);
      }else{
        return value;
      }
    }else{
      return JSON.stringify(value);
    }
  }

  isJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

}
