import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    constructor(private router: Router,
                private jwtHelperService: JwtHelperService) {}

  canActivate(route: ActivatedRouteSnapshot): any{
    const token: any = this.jwtHelperService.tokenGetter();

    if (token != null) {
        if(this.jwtHelperService.isTokenExpired(token)){
          document.location.href='/auth/login';
        }
        else{
          return true;
        }
    } else {
      document.location.href='/auth/login';
      return false;
    }

  }
}
