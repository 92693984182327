import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ApiInterceptorService} from "./app/services/api-interceptor.service";
import {JwtModule} from "@auth0/angular-jwt";
import {ServiceWorkerModule} from "@angular/service-worker";

if (environment.production) {
  enableProdMode();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(IonicModule.forRoot({})),
    provideRouter(routes),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })),
    importProvidersFrom(IonicModule.forRoot({}), HttpClientModule, JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: ['localhost','api.hydroapp.feelity.dev','hydroapp-api.kapcode.fr']
      },
    }),),
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true},
    importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production || environment.staging }))
  ],
});
